<template>
  <el-drawer  title="明细" :visible.sync="productDetail"  :direction="direction" :before-close="handleClose" destroy-on-close
   :append-to-body="true" :modal-append-to-body="false"  custom-class="drawerClass" size="50%">
    <div>
      <el-table v-loading="loading" :data="tableData" border  style="width: 100%; margin-top: 20px;" id="tables" :summary-method="getSummaries" show-summary>
        <!-- <el-table-column prop="id" label="ID" width="180"></el-table-column> -->
        <el-table-column prop="Picture" label="产品图片" >
          <template slot-scope="scope">
            <img style="width:50px;height:50px" :src="scope.row.Picture" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="InventName" label="产品名称"></el-table-column>
        <el-table-column prop="Price" label="产品单价"> </el-table-column>
        <el-table-column prop="Quantity" label="产品数量"> </el-table-column>
        <el-table-column prop="Money" label="产品总价"></el-table-column>
      </el-table>
    </div>
      <!-- <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0" @current-change="currentChange"
        @size-change="handleSizeChange">
      </el-pagination> -->
    <!-- </div> -->

    <div style="height: 50px"></div>
    <div :style="{
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '5px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 99999,
    }">
      <el-button @click="productDetail = false">取消</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { getMonthRange, toThousands} from '../../utils/Tools';
export default {
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      getMonthRange,
      toThousands,
      loading:true,
      // 日期
      range: [],
      // 产品明细列表
      tableData:[],
      productDetail: false,
      direction: "ttb",


    }
  },
  watch: {
		//监听table这个对象
		// tableData: {   // !!!!!!!注意这里注释的这段代码是方便理解  要合并几行 对应下面的
		// 	immediate: true,
		// 	handler() {
		// 		this.$nextTick(() => {
		// 			const tds = document.querySelectorAll('#tables .el-table__footer-wrapper tr>td');
		// 		// colSpan合并列
		// 		console.log(tds)
		// 			tds[0].colSpan = 3;
		// 			tds[0].style.textAlign = 'center';
		// 			tds[1].style.display = 'none';
		// 			tds[2].style.display = 'none';
		// 		});
		// 	}
		// },
		// tableData: {
		// 	// 立即监听
		// 	immediate: true,
		// 	handler() {
		// 		this.$nextTick(() => {
		// 			const tds = document.querySelectorAll('#tables .el-table__footer-wrapper tr>td');
		// 		// colSpan合并列
		// 		console.log(tds)
		// 			tds[0].colSpan = 2;
		// 			tds[0].style.textAlign = 'center';
		// 			tds[1].style.display = 'none';
		// 		});
		// 	}
		// }
	},

  methods:{

    init(){
     

    },

    returnFloat(value){

      var value=Math.round(parseFloat(value)*100)/100;

      var xsd=value.toString().split(".");

      if(xsd.length==1){

      value=value.toString()+".00";

      return value;

      }

      if(xsd.length>1){

      if(xsd[1].length<2){

      value=value.toString()+"0";

      }

      return value;

      }

    },

    

    // 合计
    getSummaries(param) {
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = '合计';
					return;
				}else if(index === 2 || index==4 || index==3){

           //index===2||index===8和9和12后的列进行求和
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)

            let sums1=sums[2]
            let sums4=sums[4]

            let newSums1 =Math.floor(sums1 * 100) / 100
            let newSums2 =Math.floor(sums4 * 100) / 100

            let price= this.returnFloat(sums1) 
            let totalPrice= this.returnFloat(sums4) 

            let number  = sums[3]

            sums[2]=price + ' 元'
            sums[4]=totalPrice+ ' 元'
          
          }

				}
			});

      console.log('打印计算后的列表', sums)
			return sums;
		},

    
   
    // 打开弹窗
    openForm(BillId){
      this.productDetail=true
      // 获取明细列表数据
      console.log('获取传递过来的明细id',BillId)
      this.getDataList(BillId)
    },

     // 获取明细
     getDataList(BillId) {
      this.loading= true
      this.$http.post('/BO/BIlling/GetTheData', {
        id: BillId
      }).then(res => {
        this.loading=false
        if (!res.Success) return this.$api.msg(res.Msg)
        console.log('获取明细列表数据',JSON.parse(res.Data.Content))
        this.tableData = JSON.parse(res.Data.Content)
      })
    },
    handleClose(done) {
      done();
    },
  }
}


</script>


<style lang="less" scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
}
.qianImg{
  width: 70px;
  height: 160px;	
  transform: rotate(-90deg);
}

.drawerClass {
  padding: 10px;
  
  .posBox {
    font-weight: 600;
    width: calc(100% - 42px);
    margin-left: 42px;
    line-height: 2;
    color: #333;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>


  