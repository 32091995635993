<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    custom-class="drawerClass"
    size="80%"
  >
    <div class="searchNav" style="padding: 0 20px">
      <el-button
        class="button"
        icon="el-icon-document"
        type="primary"
        style="margin-left: 0"
        :loading="loading"
        @click="arrearListAdd"
        >添加</el-button
      >
      <!-- <h2>联系人</h2> -->
      <div class="block">
        <el-date-picker
          v-model="range"
          type="daterange"
          style="width: 250px; margin-left: 10px"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="changeTime"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <div class="searchParams">
        <!-- <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div> -->
        <!-- <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="请输入分类名称"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button> -->
      </div>
    </div>
    <div style="padding: 20px">
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
      >
        <el-table-column prop="CreateTime" label="对账日期">
          <template slot-scope="scope">
            {{ scope.row.CreateTime?.substring(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column prop="CustomerName" label="客户"> </el-table-column>
        <el-table-column prop="CustomerName" label="账期" width="200">
          <template slot-scope="scope">
            {{
              scope.row.StartTime?.substring(0, 10) +
              "  ~  " +
              scope.row.EndTime?.substring(0, 10)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="Balance" label="上期余额">
          <template slot-scope="scope">
            {{ toThousands(scope.row.Balance) }}
          </template>
        </el-table-column>
        <el-table-column prop="Arrears" label="本期新欠">
          <template slot-scope="scope">
            {{ toThousands(scope.row.Arrears) }}
          </template>
        </el-table-column>
        <el-table-column prop="Repayment" label="本期还款">
          <template slot-scope="scope">
            {{ toThousands(scope.row.Repayment) }}
          </template>
        </el-table-column>
        <el-table-column prop="Residue" label="本期余额">
          <template slot-scope="scope">
            {{ toThousands(scope.row.Residue) }}
          </template>
        </el-table-column>
        <el-table-column prop="IsReconcile" label="状态">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.IsReconcile == 0 || scope.row.IsReconcile == null"
              type="info"
              >未对</el-tag
            >
            <el-tag v-else>已对</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="Signature" label="签章" width="200">
          <template slot-scope="scope">
            <img
              v-if="scope.row.Signature !== ''"
              class="qianImg"
              style=""
              :src="scope.row.Signature"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>

    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="drawer = false">取消</el-button>
    </div>
    <el-dialog
      title="编辑商品分类"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      append-to-body
    >
      <el-input
        v-model="typeObj.Name"
        placeholder="请输入商品分类名称"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          :loading="dialogLoading"
          type="primary"
          @click="dialogConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 对账单查看组件 -->
    <ReconArrearDetail ref="ReconArrearDetail" :parentObj="{ ...this }" />
  </el-drawer>
</template>

<script>
import { getMonthRange, toThousands, formatDate } from "../../utils/Tools";
import ReconArrearDetail from "./ReconArrearDetail.vue";
export default {
  components: {
    ReconArrearDetail,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      getMonthRange,
      toThousands,
      formatDate,
      // 日期
      range: [],
      drawer: false,
      direction: "rtl",
      btnLoading: false,
      title: "",
      loading: false,
      timeout: null,
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: {
        // 客户id
        CustomerId: "",
        // 开始时间
        StartTime: "",
        // 结束时间
        EndTime: "",
      },
      sortList: [1, 2, 3, 4, 5, 6],
      selectRows: [],
      activeName: "2",
      tagList: [],
      dialogVisible: false,
      typeObj: {},
      dialogLoading: false,
      // 传递过来的客户id
      CustomerId: "",
      // 传递过来的公司id
      CompanyId: "",
    };
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 对账单添加
    arrearListAdd() {
      let obj = {
        CustomerId: this.CustomerId,
        CompanyId: this.CompanyId,
        range: this.range,
        length: this.tableData.length,
      };
      console.log("对账单添加了", obj, this.tableData.length);
      this.$refs.ReconArrearDetail.openForm("添加", obj);
    },
    // 开始日期结束日期选择事件
    changeTime(e) {
      console.log("时间选择", e);
      if (e) {
        this.queryParam.StartTime = e[0];
        this.queryParam.EndTime = e[1];
      }

      this.tableData = [];
      this.loading = true;
      this.getBillConfirmList();
    },
    dialogConfirm() {
      if (!this.typeObj.Name) return this.$message.error("请输入商品分类名称");
      if (!this.typeObj.CompanyId)
        this.typeObj.CompanyId = this.queryParam.CommpayId;
      this.dialogLoading = true;
      this.$http.post("/BO/InventType/SaveData", this.typeObj).then((res) => {
        this.dialogLoading = false;
        if (res.Success) {
          this.dialogVisible = false;
          this.$message.success("操作成功");
          this.getDataList();
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 列表项查看
    handleView(row) {
      let obj = {
        BillConfirmId: row.Id,
        CustomerId: this.CustomerId,
        CompanyId: row.CompanyId,
        range: this.range,
        StartTime: row.StartTime,
        EndTime: row.EndTime,
        Type: JSON.parse(localStorage.getItem("CompanyInfoUserInfo")).SignType,
      };
      console.log(
        "查看了查看了查看了查看了",
        row,
        obj,
        sessionStorage.getItem("token")
      );

      this.$refs.ReconArrearDetail.openForm("查看", obj);
      // this.typeObj = row;
      // this.dialogVisible = true;
    },
    // 分类新增
    handleAdd() {},
    currentChange(page) {
      console.log("分页1", page);
      this.pagination.current = page;
      this.tableData = [];
      this.getBillConfirmList();
    },
    handleSizeChange(lint) {
      console.log("分页2", lint);
      this.tableData = [];
      this.pagination.pageSize = lint;
      this.getBillConfirmList();
    },
    saveCustomerGoods() {
      this.$http
        .post("/BO/InventCustomers/AddDataList?Code=" + this.entity.cInvCode, {
          Data: this.tableData,
        })
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.parentObj.getDataList();
            this.drawer = false;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    handleSelectionChange(val) {
      this.selectRows = val;
    },

    handleDelete(scope) {
      this.tableData.splice(scope.$index, 1);
    },
    // 公司选择
    CompanySelect(row) {
      this.entity.CompanyName = row.Title;
      this.entity.CompanyId = row.Id;
    },
    init() {
      this.drawer = true;
    },
    // 获取欠款对账单
    getBillConfirmList() {
      this.loading = true;
      this.$http
        .post("/BO/BIllcollection/GetBillConfirmList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            console.log("获取欠款对账单", resJson.Data);
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          } else {
            this.loading = false;
          }
        });
    },
    openForm(Id, CommpayId) {
      this.CustomerId = Id;
      this.CompanyId = CommpayId;
      console.log(
        "传递过来的两个id传递过来的两个i传递过来的两个id传递过来的两个idd",
        this.CustomerId,
        this.CompanyId,
        JSON.parse(sessionStorage.getItem("userInfo")).Id
      );

      // 获取公司信息
      this.$http
        .post("/Base_Manage/Base_Company/GetDataCompanyData", {
          Search: {
            condition: "CreatorId",
            keyword: JSON.parse(sessionStorage.getItem("userInfo")).Id,
          },
        })
        .then((resJson) => {
          if (resJson.Success && resJson.Data.length > 0) {
            localStorage.setItem(
              "CompanyInfoUserInfo",
              JSON.stringify(resJson.Data[0])
            );
          } else {
          }
        });

      this.init();
      this.title = "对账单";
      // 获取当前年月日
      let currentDate = new Date(); // 获取当前日期和时间
      currentDate.setMonth(currentDate.getMonth() - 1); // 将月份减去1
      // 获取当前一个月的前一个月份年月日
      let currentEndDate = getMonthRange(currentDate);
      let StartTime = formatDate("day");
      this.queryParam.StartTime = currentEndDate;
      this.queryParam.EndTime = StartTime;
      // 默认赋值顶部时间范围
      this.range[0] = this.queryParam.StartTime;
      this.range[1] = this.queryParam.EndTime;
      console.log(
        "获取开始时间技术时间",
        this.queryParam.StartTime,
        this.queryParam.EndTime
      );
      if (Id) {
        this.queryParam.CustomerId = Id;
        this.getBillConfirmList();
      }
      console.log("获取月份了==================", this.queryParam);
    },
    // 获取商品分类列表数据
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/InventType/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },

    handleClose(done) {
      done();
    },
    handleSelect(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
}
.qianImg {
  width: 70px;
  height: 160px;
  transform: rotate(-90deg);
}

.drawerClass {
  padding: 10px;

  .posBox {
    font-weight: 600;
    width: calc(100% - 42px);
    margin-left: 42px;
    line-height: 2;
    color: #333;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
