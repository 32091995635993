<template>
  <el-drawer
    :title="title"
    :visible.sync="drawerDetail"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    :append-to-body="true"
    :modal-append-to-body="false"
    custom-class="drawerClass"
    size="70%"
  >
    <!-- width:200px;border:1px solid blue -->
    <div id="qrcode" class="qrcode" ref="qrCodeUrl" style=""></div>
    <div class="searchNav" style="padding: 0 20px">
      <el-button
        class="button"
        icon="el-icon-document"
        type="primary"
        style="margin-left: 0"
        :loading="loading"
        @click="arrearListSend"
        >发送</el-button
      >
      <!-- <el-button class="button" type="text" @click="EditEmail"
        >配置邮箱</el-button> -->
      <!-- <div style="border:1px solid red">
        <img id="ewmsrc" width="340px" height="340px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAA8AXHiAAAAAXNSR0IArs4c6QAAAqFJREFUeF7t0jENAAAMw7CVP+mhyOcC6BF5ZwoEBRZ8ulTgwIIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjoFi4GkAFhJVqdgMZAUACvJ6hQsBpICYCVZnYLFQFIArCSrU7AYSAqAlWR1ChYDSQGwkqxOwWIgKQBWktUpWAwkBcBKsjp9QYIAl6bSsVAAAAAASUVORK5CYII=" />
      </div> -->
      <!-- <h2>联系人</h2> -->
      <div class="block" v-if="titleType === '添加'">
        <el-date-picker
          v-model="range"
          type="daterange"
          style="width: 250px; margin-left: 10px"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="changeTime"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <div class="searchParams"></div>
    </div>
    <div style="padding: 15px 30px 10px 30px">
      <el-row style="margin-bottom: 5px">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-row>
              <el-col :span="4"> 客户: </el-col>
              <el-col :span="18">
                {{ entity.CustomerName }}
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-row>
              <el-col :span="4"> 电话: </el-col>
              <el-col :span="18">
                {{ entity.CustomerPhone ? entity.CustomerPhone : "" }}
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 5px">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-row>
              <el-col :span="4"> 地址: </el-col>
              <el-col :span="18">
                {{ entity.CustomerAddress ? entity.CustomerAddress : "" }}
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-row>
              <el-col :span="4"> 账期: </el-col>
              <el-col v-if="entity.StartTime || entity.EndTime" :span="18">
                {{ entity.StartTime ? entity.StartTime : "" }}
                ~ {{ entity.EndTime ? entity.EndTime : "" }}
              </el-col>
              <el-col v-else :span="18">
                {{ range[0] }}
                ~ {{ range[1] }}
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 5px">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-row>
              <el-col :span="4"> 上期余额: </el-col>
              <el-col :span="18">
                {{ toThousands(entity.Balance) }}
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-row>
              <el-col :span="4"> 本期新欠: </el-col>
              <el-col :span="18">
                {{ toThousands(entity.Arrears) }}
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 5px">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-row>
              <el-col :span="4"> 本期还款: </el-col>
              <el-col :span="18">
                {{ toThousands(entity.Repayment) }}
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-light">
            <el-row>
              <el-col :span="4"> 本期余额: </el-col>
              <el-col :span="18">
                {{ toThousands(entity.Residue) }}
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="padding: 20px">
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
      >
        <el-table-column prop="CreateTime" label="日期">
          <template slot-scope="scope">
            {{ scope.row.CreateTime?.substring(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column prop="Type" label="类型" width="200">
          <template slot-scope="scope">
            <span>{{
              scope.row.Type == 0
                ? "还款"
                : scope.row.Type == 1
                ? "赊货"
                : "作废"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Money" label="金额">
          <template slot-scope="scope">
            {{ toThousands(scope.row.Money) }}
          </template>
        </el-table-column>
        <el-table-column prop="Owing" label="欠款金额">
          <template slot-scope="scope">
            {{ toThousands(scope.row.Owing) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.Type != 0"
              type="text"
              :loading="loadingCurrent === scope.$index"
              @click="handleView(scope.row, scope.$index)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0" @current-change="currentChange"
        @size-change="handleSizeChange">
      </el-pagination> -->
    </div>

    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="drawerDetail = false">取消</el-button>
      <el-button v-if="saveBtnShow" @click="arrearDetailSave" type="primary"
        >保存</el-button
      >
      <el-button v-else @click="arrearDetailDelete" type="danger"
        >删除</el-button
      >
    </div>

    <el-dialog
      title="请选择发送方式"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      append-to-body
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-around;
        "
      >
        <div style="display: flex; flex-direction: column; align-items: center">
          <img
            style="width: 75%; margin-bottom: 10px"
            src="../../assets/weixin.png"
            alt=""
          />
          <el-radio v-model="radio" label="1" @change="radioChange"
            >微信</el-radio
          >
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            flex-direction: column;
            align-items: center;
          "
        >
          <img
            style="width: 100%; margin-bottom: 10px"
            src="../../assets/duanxin.png"
            alt=""
          />
          <el-radio v-model="radio" label="2" @change="radioChange"
            >短信</el-radio
          >
        </div>
        <!-- <div
          style="
            display: flex;
            align-items: center;
            flex-direction: column;
            align-items: center;
          "
        >
          <img
            style="width: 85%; margin-bottom: 10px"
            src="../../assets/youxiang.png"
            alt=""
          />
          <el-radio v-model="radio" label="3" @change="radioChange"
            >邮箱</el-radio
          >
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          :loading="dialogLoading"
          type="primary"
          @click="dialogConfirmSend"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 微信分享 -->
    <el-dialog
      title="请使用微信扫一扫"
      :visible.sync="dialogVisibleWechart"
      width="300px"
      :append-to-body="true"
    >
      <div style="display: flex; justify-content: center">
        <vue-qr
          :logoSrc="require('@/assets/titlelogo.png')"
          :text="codeValue"
          :logoScale="0.2"
          :size="200"
        ></vue-qr>
      </div>
    </el-dialog>

    <!-- 客户输入手机号 -->
    <el-dialog
      title="发送对账单"
      :visible.sync="dialogVisibleCustomerPhone"
      width="30%"
      :before-close="handleClose"
      append-to-body
    >
      <el-input
        v-model="CustomerPhone"
        placeholder="请输入客户手机号"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCustomerPhone = false">取 消</el-button>
        <el-button
          :loading="dialogLoadingCustomerPhone"
          type="primary"
          @click="dialogConfirmCustomerPhone"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 客户邮箱输入 -->
    <el-dialog
      title="客户邮箱"
      :visible.sync="dialogVisibleEmail"
      width="30%"
      :append-to-body="true"
    >
      <el-input v-model="Email" placeholder="请输入客户邮箱"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEmail = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="dialogLoadingEmail"
          @click="handleSubmitEamil"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 微信分享 -->

    <vue-qr
      style="display: none"
      id="emailQrCode"
      :logoSrc="require('@/assets/titlelogo.png')"
      :text="emailQrCode"
      :logoScale="0.2"
      :size="200"
    ></vue-qr>
    <!-- 小票页面组件 -->
    <ReceiptPage ref="receiptPage" :parentObj="{ ...this }" />

    <!-- 对账函组件 -->
    <Form ref="Form" :parentObj="{ ...this }" />
    <!-- 邮箱 -->
    <Email ref="Email" :parentObj="{ ...this }" />
    <!-- 明细组件 -->
    <!-- <ProductDetail ref="ProductDetail" :parentObj="{ ...this }" /> -->
  </el-drawer>
</template>

<script>
// import ProductDetail from './ProductDetail.vue'
import { getMonthRange, toThousands, formatDate } from "../../utils/Tools";
// 引入对账函组件
import Form from "../Overdue/Form.vue";
import Email from "./Email.vue";
// 引入生成二维码的工具
import QRCode from "qrcodejs2";
// 引入小票页面
import ReceiptPage from "../Overdue/Receipt.vue";
import VueQr from "vue-qr";
import { log } from "console";
export default {
  components: {
    Email,
    ReceiptPage,
    VueQr,
    Form,
    // ProductDetail
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      // 输入的客户邮箱
      Email: "",
      // 客户邮箱加载
      dialogLoadingEmail: false,
      // 是否显示客户邮箱弹窗
      dialogVisibleEmail: false,
      isSave: false,
      loadingCurrent: null,
      sendMessage: "", // 转发内容
      // 客户手机号
      CustomerPhone: "",
      // 是否显示客户手机号弹窗
      dialogVisibleCustomerPhone: false,
      // 输入客户手机号加载状态
      dialogLoadingCustomerPhone: false,
      codeValue: "",
      // 是否弹窗微信扫码页面
      dialogVisibleWechart: false,
      // 微信  短信
      radio: "1",
      getMonthRange,
      formatDate,
      toThousands,
      // 日期
      range: [],
      drawerDetail: false,
      direction: "rtl",
      btnLoading: false,
      title: "",
      loading: false,
      timeout: null,
      entity: {},
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: {
        // 客户id
        CustomerId: "",
        // 开始时间
        StartTime: "",
        // 结束时间
        EndTime: "",
      },
      sortList: [1, 2, 3, 4, 5, 6],
      selectRows: [],
      activeName: "2",
      tagList: [],
      dialogVisible: false,
      typeObj: {},
      dialogLoading: false,
      // 传递过来的对账单列表id
      BillConfirmId: "",
      // 客户id
      CustomerId: "",
      CompanyId: "",
      // 时间范围
      range: [],
      // 点击的是添加还是查看
      titleType: "",
      // 传递过来的开始时间
      StartTime: "",
      // 传递过来的结束时间
      EndTime: "",
      // 传递过来的缓存type
      SingType: "",
      href: "",
      // 生成的base64二维码图片
      qrcodeimg: "",
      // 存储生成的邮箱4二维码
      emailQrCode: "",
      // 传递过来的对账单列表  判断是否有列表数据来允许是否可以点击发送按钮
      tableLength: "",
      // 是否隐藏删除按钮
      saveBtnShow: false,
      // 发送邮箱传递额html模板
      html: "",
      content: "",
      Url: "",
    };
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  created() {},
  methods: {
    // 配置邮箱
    EditEmail() {
      console.log("配置邮箱", this.CompanyId, this.entity);
      if (!this.CompanyId) return this.$message.error("请先选择公司");
      console.log("可以继续执行了");

      this.$refs.Email.openForm(this.CompanyId);
    },

    // 客户邮箱输入后提交
    handleSubmitEamil() {
      let obj = {
        Id: this.BillConfirmId,
        Title: this.entity.CompanyName,
        cCusName: this.entity.CustomerName,
        Sender: this.$refs.Email.entity.Email,
        SenderCode: this.$refs.Email.entity.EmailCode,
        Recipient: this.Email,
        Content: this.content,
        Url: this.Url,
        qrCode: this.html,
      };

      console.log("客户邮箱输入提交", obj);
      const loading = this.$loading({
        lock: true,
        text: "发送中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$http.post("/BO/bill_confirm/SendMail", obj).then((res) => {
        this.loading = false;
        setTimeout(() => {
          loading.close();
        }, 1000);
        this.CompanyLoading = false;
        if (res.Success) {
          this.$message.success("发送成功");
          this.dialogVisibleEmail = false;
        } else {
          this.$message.error(res.Msg);
          this.dialogVisibleEmail = false;
        }
      });
    },
    // 对账单发送确认
    dialogConfirmSend() {
      console.log("发送了");
      // 隐藏微信短信选择弹窗
      this.dialogVisible = false;
      // 选择的是微信发送
      if (this.radio === "1") {
        // let _url = location.href.split("#")[0] + "#/ReconArrearShare?data=";
        let _url = "https://shq.yoojet.com/#/ReconArrearShare?data=";
        // let _url = "https://hzq.yoojet.com/#/ReconArrearShare?data=";
        _url += JSON.stringify({
          // 传递过来的对账单列表id
          BillConfirmId: this.BillConfirmId,
          // 客户id
          CustomerId: this.CustomerId,
          CompanyId: this.CompanyId,
          // 传递过来的开始时间
          StartTime: this.StartTime ? this.StartTime.substring(0, 10) : "",
          // 传递过来的结束时间
          EndTime: this.EndTime ? this.EndTime.substring(0, 10) : "",
          Type: this.SingType,
        });
        console.log(_url);
        this.codeValue = _url + "&show=true";

        this.dialogVisibleWechart = true;
      } else if (this.radio === "2") {
        // 选择的是微信发送
        // 如果不存在客户手机号  输入手机号  否则直接获取手机号调用接口
        if (this.entity.CustomerPhone == null) {
          this.dialogVisibleCustomerPhone = true;
        } else {
          let data = `data=CompanyId:${this.CompanyId},CustomerId:${
            this.CustomerId
          },BillConfirmId:${this.BillConfirmId},StartTime:${
            this.StartTime ? this.StartTime.substring(0, 10) : ""
          },EndTime:${this.EndTime ? this.EndTime.substring(0, 10) : ""},Type:${
            this.SingType
          }`;
          this.sendRequest(
            this.entity.CustomerPhone,
            data,
            this.entity.CustomerName
          );
        }
      } else {
        // 选择的是邮箱

        let qrcodeEle = document.getElementById("emailQrCode");
        // 生成的二维码路径
        this.qrcodeimg = qrcodeEle.src;
        // 发送
        let html = `
        <div style=''>
          <h3 style='margin-bottom:10px;padding-left:50px;'>${
            this.entity.CustomerName + "  " + "您好:"
          }</h3>
          <h5 style='margin-bottom:10px;padding-left:100px;'>您的账单信息如下:</h5>
          <div style='margin-bottom:10px;padding-left:100px;'>账期:${
            " " +
            this.entity.StartTime.substring(0, 10) +
            " ~ " +
            this.entity.EndTime.substring(0, 10)
          }</div>
          <div style='margin-bottom:20px;padding-left:100px;'>本期新欠:${
            " " + toThousands(this.entity.Residue) + "元"
          }</div>
          <div style='margin-bottom:40px;padding-left:100px;font-weight:bold'>请扫描下面二维码进行确认</div>
          <div style='margin-bottom:20px;text-align:center;'>
            <img src="${this.qrcodeimg}" />
          </div>
          
        </div>
        `;

        this.html = html;

        //显示客户邮箱
        this.dialogVisibleEmail = true;

        // return

        // console.log('邮箱传参', html)
        //  this.$refs.Email.openForm(this.BillConfirmId, this.entity.CompanyName, this.entity.CustomerName, html)
      }
    },

    // 客户输入手机号确定
    dialogConfirmCustomerPhone() {
      this.dialogVisibleCustomerPhone = false;
      let data = `data=CompanyId:${this.CompanyId},CustomerId:${
        this.CustomerId
      },BillConfirmId:${this.BillConfirmId},StartTime:${
        this.StartTime ? this.StartTime.substring(0, 10) : ""
      },EndTime:${this.EndTime ? this.EndTime.substring(0, 10) : ""},Type:${
        this.SingType
      }`;
      this.sendRequest(this.CustomerPhone, data, this.entity.CustomerName);
    },
    // 短信方式发送对账单
    sendRequest(CustomerPhone, data, CustomerName) {
      const loading = this.$loading({
        lock: true,
        text: "发送中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$http
        .post("/BO/BIllcollection/SendCollection", [
          {
            CollectionPhone: CustomerPhone,
            CollectionValue: data,
            CollectionDW: CustomerName,
            CollectionId: this.BillConfirmId,
            AppType:'AppShq'
          },
        ])
        .then((res) => {
          setTimeout(() => {
            loading.close();
          }, 1000);
          if (res.Success) this.$message.success("发送成功");
          else this.$message.error(res.Msg);
        });
    },
    // 微信短信邮箱方式选择
    radioChange(e) {
      console.log("选择选择选择选择", e);
    },
    // 对账单发送
    arrearListSend() {
      if (!this.BillConfirmId) return this.$message.error("请保存后再进行分享");
      // console.log('this',)
      // if(!this.$refs.Email.entity.Email){
      //   this.$message.error('请先配置邮箱')
      //   return
      // }
      this.dialogVisible = true;
    },
    // 对账单添加
    arrearListAdd() {},
    // 开始日期结束日期选择事件
    changeTime(e) {
      this.range = e;
      this.getBillConfirmList();
    },
    dialogConfirm() {
      if (!this.typeObj.Name) return this.$message.error("请输入商品分类名称");
      if (!this.typeObj.CompanyId)
        this.typeObj.CompanyId = this.queryParam.CommpayId;
      this.dialogLoading = true;
      this.$http.post("/BO/InventType/SaveData", this.typeObj).then((res) => {
        this.dialogLoading = false;
        if (res.Success) {
          this.dialogVisible = false;
          this.$message.success("操作成功");
          this.getDataList();
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 查看商品详情小票页面
    handleView(row, index) {
      if (this.loadingCurrent !== null) return;
      this.typeObj = row;
      this.loadingCurrent = index;
      // 备用
      // this.$http
      //   .post("/BO/BIlling/GetOrderBillReturnId?Id=" + row.BillId)
      //   .then((res) => {
      //     console.log('是否获取到了',res)
      //     if (res.Success) {
      //       const loading = this.$loading({
      //         lock: true,
      //         text: '加载中',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.7)'
      //       });
      //       if(res.Data.Id ==""){
      //         this.$message.error('没有回执单信息')
      //         return
      //       }
      //       // 打开回执单页面
      //         this.$refs.Form.openForm(res.Data.Id);
      //         setTimeout(() => {
      //         loading.close();
      //       }, 2500);
      //     } else {
      //     }
      //   });

      // this.dialogVisible = true;
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 打开明细弹窗
      // this.$refs.receiptPage.openForm(row.Id);  不要删除
      this.$http
        .post("/BO/BIlling/GetOrderReturnId?Id=" + row.BillId)
        .then((res) => {
          this.loadingCurrent = null;
          if (res.Success) {
            setTimeout(() => {
              loading.close();
            }, 2500);
            if (res.Data.Type == 0) {
              // 打开小票页面
              this.$refs.receiptPage.openForm(res.Data.Id);
            } else {
              // 打开回执单页面
              this.$refs.Form.openForm(res.Data.Id);
            }
          }
        });
    },
    // 分类新增
    handleAdd() {},
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    saveCustomerGoods() {
      this.$http
        .post("/BO/InventCustomers/AddDataList?Code=" + this.entity.cInvCode, {
          Data: this.tableData,
        })
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.parentObj.getDataList();
            this.drawerDetail = false;
            // // 显示删除按钮
            // this.saveBtnShow = true;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    handleSelectionChange(val) {
      this.selectRows = val;
    },

    handleDelete(scope) {
      this.tableData.splice(scope.$index, 1);
    },
    init() {
      this.drawerDetail = true;
    },

    // 删除
    arrearDetailDelete() {
      this.$http
        .post("/BO/BIllcollection/DeleteConfirmData", [this.BillConfirmId])
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            // 刷新父组件的列表方法
            this.parentObj.tableData = [];
            this.parentObj.getBillConfirmList();
            this.$message.success("已删除");
            // 关闭当前弹窗
            this.drawerDetail = false;
          } else {
            this.$message.error(resJson.Msg);
            this.loading = false;
          }
        });
    },

    // 保存
    arrearDetailSave() {
      if (this.tableData.length == 0)
        return this.$message.error("暂无匹配数据,请更改日期尝试");
      this.loading = true;
      this.$http
        .post("/BO/BIllcollection/SaveBillConfirmData", this.entity)
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            // 刷新父组件的列表方法
            this.parentObj.tableData = [];
            this.parentObj.getBillConfirmList();
            this.$message.success("已保存");
            this.StartTime = this.range[0];
            this.EndTime = this.range[1];
            // 关闭当前弹窗
            // this.drawerDetail = false;
            this.BillConfirmId = resJson.Data.Id;
            // 显示删除按钮
            this.saveBtnShow = false;
            // console.log('是否复制id了', this.BillConfirmId, resJson.D)
          } else {
            this.$message.error(resJson.Msg);
            this.loading = false;
          }
        });
    },
    // 获取欠款对账单
    getBillConfirmList() {
      this.loading = true;
      this.entity = {};
      this.tableData = [];
      this.$http
        .post("/BO/BIllcollection/GetReconciliationData", {
          BillConfirmId: this.BillConfirmId,
          CompanyId: this.CompanyId,
          CustomerId: this.CustomerId,
          EndTime: this.range[1],
          StartTime: this.range[0],
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            this.entity = resJson.Data;
            this.tableData = resJson.Data.BIllcollectionList;
            // 时间格式化
            if (
              this.entity.StartTime !== null ||
              this.entity.EndTime !== null
            ) {
              this.entity.StartTime = this.entity.StartTime.replace(
                "0:00:00",
                ""
              );
              this.entity.EndTime = this.entity.EndTime.replace("0:00:00", "");
            }
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          } else {
            this.loading = false;
          }
        });
    },
    openForm(titleType, obj, token) {
      this.titleType = titleType;
      this.BillConfirmId = obj.BillConfirmId;
      this.CustomerId = obj.CustomerId;
      this.CompanyId = obj.CompanyId;
      this.StartTime = obj.StartTime;
      this.EndTime = obj.EndTime;
      this.SingType = obj.Type;
      this.range = obj.range;
      this.tableLength = obj.length;

      // 删除保存
      if (!this.BillConfirmId) this.saveBtnShow = true;
      else this.saveBtnShow = false;
      let baseUrl = "https://shq.yoojet.com/";
      // let _url = location.href.split("#")[0] + "#/ReconArrearShare?data=";
      let _url = baseUrl + "#/ReconArrearShare?data=";
      _url += JSON.stringify({
        // 传递过来的对账单列表id
        BillConfirmId: this.BillConfirmId,
        // 客户id
        CustomerId: this.CustomerId,
        CompanyId: this.CompanyId,
        // 传递过来的开始时间
        StartTime: this.StartTime ? this.StartTime.substring(0, 10) : "",
        // 传递过来的结束时间
        EndTime: this.EndTime ? this.EndTime.substring(0, 10) : "",
        Type: this.SingType,
      });

      _url += "&show=true";
      this.emailQrCode = _url;

      let userInfoId = JSON.parse(sessionStorage.getItem("userInfo")).Id;
      // 获取公司信息
      this.$http
        .post("/Base_Manage/Base_Company/GetDataCompanyData", {
          Search: {
            condition: "CreatorId",
            keyword: userInfoId,
          },
        })
        .then((res) => {
          if (res.Success && res.Data.length > 0) {
            let data = res.Data[0];
            localStorage.setItem("CompanyInfoUserInfo", JSON.stringify(data));
          }
        });

      this.init();
      this.title = "对账单";
      // 获取当前年月日
      // let currentDate = new Date(); // 获取当前日期和时间
      // currentDate.setMonth(currentDate.getMonth() - 1); // 将月份减去1
      // // 获取当前一个月的前一个月份年月日
      // let currentEndDate = getMonthRange(currentDate);
      // let StartTime = formatDate("day");
      // this.queryParam.StartTime = currentEndDate;
      // this.queryParam.EndTime = StartTime;
      // if (obj.BillConfirmId) {
      // this.queryParam.CustomerId = BillConfirmId;
      this.getBillConfirmList();
      // }
    },

    // 获取商品分类列表数据
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/InventType/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data.map((item) => {
              return {
                ...item,
                loading: false,
              };
            });
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },

    handleClose(done) {
      done();
    },
    handleSelect(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
}
.qianImg {
  width: 70px;
  height: 160px;
  transform: rotate(-90deg);
}

.drawerClass {
  padding: 10px;
  .posBox {
    font-weight: 600;
    width: calc(100% - 42px);
    margin-left: 42px;
    line-height: 2;
    color: #333;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
