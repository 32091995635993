<template>
  <el-dialog title="邮箱发送" :visible.sync="dialogVisible" width="30%" :append-to-body="true"> 
    <el-form :model="entity" :rules="rules" ref="form" label-width="100px">
      <el-form-item label="发送人邮箱" prop="Email">
        <el-input v-model="entity.Email"></el-input>
      </el-form-item>
      <!-- <el-form-item label="接收人邮箱" prop="Recipient">
        <el-input v-model="entity.Recipient"></el-input>
      </el-form-item> -->
      <el-form-item label="授权码" prop="EmailCode">
        <el-input v-model="entity.EmailCode"></el-input>
      </el-form-item>
      <el-form-item label="帮助">
        <el-image
          class="image"
          v-for="item in srcList"
          :key="item"
          :src="item"
          :preview-src-list="srcList"
        >
        </el-image>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleClose"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      Id: "",
      entity: {},
      srcList: [
        "https://hzq.yoojet.com/email1.jpg",
        "https://hzq.yoojet.com/email2.jpg",
        "https://hzq.yoojet.com/email3.jpg",
      ],
      rules: {
        Email: [{ required: true, message: "请输入发送人邮箱", trigger: "blur" }],
        Recipient: [{ required: true, message: "请输入接收人邮箱", trigger: "blur" }],
        EmailCode: [
          { required: true, message: "请输入授权码", trigger: "blur" },
        ],
      },
      CompanyName:"",
      CustomerName:"",
      Content:"",
      Id:"",
      Url:"",
      href:"",
      qrCode:""


    };
  },
  methods: {
    openForm(Id) {
      console.log('传递过来的公司id', Id)
      this.Id = Id
      // this.loadin = true;
      this.dialogVisible = true;
      // this.$nextTick(() => {
      //   this.$refs.form.clearValidate();
      // });
      // 获取公司信息
      this.$http
        .post("/Base_Manage/Base_Company/GetTheData?id=" + Id, {})
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            console.log('获取公司信息', res.Data)
            this.entity = res.Data;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 发送邮箱
    handleClose() {
     

      // let obj ={
      //   Id:this.Id,
      //   Title:this.CompanyName,
      //   cCusName:this.CustomerName,
      //   Sender:this.entity.Email,
      //   SenderCode:this.entity.EmailCode,
      //   Recipient:this.entity.Recipient,
      //   Content:this.content,
      //   Url:this.Url,
      //   qrCode:this.qrCode
      // }
      // console.log('获取参数获取参数获取参数获取参数',obj)
      // const loading = this.$loading({
      //     lock: true,
      //     text: '发送中',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)'
      //   });
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.dialogVisible = false;
        // this.$http
        //   .post("/BO/bill_confirm/SendMail", obj)
        //   .then((res) => {
        //     this.loading=false
        //     setTimeout(() => {
        //       loading.close();
        //     }, 1000);
        //     this.CompanyLoading = false;
        //     if (res.Success) {
        //       this.$message.success("发送成功");
        //       this.dialogVisible = false;
        //     } else {
        //       this.$message.error(res.Msg);
        //       this.dialogVisible = false;
        //     }
        //   });
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.image {
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  margin-right: 8px;
}
</style>